import { Banner, Color, FileAsset, Page } from "@shapeable/types";
import { COLOR_WHITE } from "@shapeable/ui";
import { COLOR_GREEN } from "./theme";

/* Canned entities to speed things up a bit */


export const createIntroSlice = (entity: Page) => ({
  id: 'intro-slice',
  intro: entity.intro,
  backgroundColor: COLOR_GREEN,
  color: COLOR_WHITE,
  layout: {
    id: 'slice-layout-intro',
    slug: 'slice-layout-intro',
    component: 'SliceLayoutIntro',
  }
});

export const createContentSlice = (entity: Page) => ({
  id: 'intro-slice',
  content: entity.outro,
  layout: {
    id: 'slice-layout-content-image',
    slug: 'slice-layout-content-image',
    component: 'SliceLayoutContentImage',
  }
});


export const disclaimer = "The views and opinions expressed in the content published on our platform are solely those of the respective authors and do not necessarily reflect the views or opinions of the hosting platform."