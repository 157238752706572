import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Page, Shapeable, Topic } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntitySelect, entitySelectDefaultTransform, EntitySelectOptionData, SliceLayoutBoundary, useChallenges, useEntity, useLang, useReady, useTopics } from '@shapeable/ui';
import { StrategicIntelligenceWidget } from '../elements/strategic-intelligence-widget';
import { classNames } from '@shapeable/utils';
const cls = classNames('page-layout-strategic-intelligence');
import { first, get, filter, sortBy, find } from 'lodash';
import { DARK_GREEN, GREEN, LIGHT_BIEGE } from '../../theme';

// -------- Types -------->

export type SliceStrategicIntelligenceProps = Classable & HasChildren & { 
  entity?: Page;
};

export const SliceStrategicIntelligenceDefaultProps: Omit<SliceStrategicIntelligenceProps, 'entity'> = {
};

// -------- Child Component Props -------->

// -------- Styles -------->


const ContainerStyles = breakpoints({
  base: css`
  `,
});


const WidgetStyles = breakpoints({
  base: css`
  `,
});

const TopicSelectStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(4)};
    width: 100%;
    max-width: 600px;
  `,
  tablet: css`
    margin-bottom: ${theme.UNIT(0)};
  `
});

const BodyStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(4)};
    padding-bottom: 0;
  `,
  tablet: css`
    padding: ${theme.UNIT(6)};
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div`${ContainerStyles}`,

  Body: styled.div`${BodyStyles}`,

    TopicSelect: styled(EntitySelect)`${TopicSelectStyles}`,
  
  Widget: styled(StrategicIntelligenceWidget)`${WidgetStyles}`,
};

export const SliceStrategicIntelligence: Shapeable.FC<SliceStrategicIntelligenceProps> = (props) => {
const { className } = props;
  const entity = useEntity(props.entity);

  const t = useLang();

  const { isReady } = useReady();
  
  const topics = useTopics() || [];
  const challenges = useChallenges() || [];
  const firstTopic = first(topics);
  const selectItems = sortBy(filter([...topics, ...challenges], item => !!item.dataSetId), 'name');
  const defaultSelection = find(selectItems, item => item.slug === process.env.DEFAULT_TOPIC) || firstTopic;
  const [ selection, setSelection ] = React.useState<Topic>(defaultSelection);

  if (!process.env.WEF_STRATEGIC_INTELLIGENCE_KEY) {
    console.warn("Strategic Intelligence Widget Cannot be used without setting WEF_STRATEGIC_INTELLIGENCE_KEY environment var");
  }

  const value = selection && entitySelectDefaultTransform(selection);
  
  return (

    <My.Container className={cls.name(className)}>
      <My.Body>
        <SliceLayoutBoundary>
          <My.TopicSelect 
            isClearable={false}
            onChange={(selection: EntitySelectOptionData) => { setSelection(selection.entity) }}
            value={value}
            placeholder={t('Select a Topic')}
            items={selectItems} />
        </SliceLayoutBoundary>
      </My.Body>
      {
        isReady &&
        <My.Widget
          key={`widget-${selection.dataSetId}`}
          apiKey={process.env.WEF_STRATEGIC_INTELLIGENCE_KEY}
          topicId={selection.dataSetId}
          primaryColor={DARK_GREEN}
          secondaryColor={LIGHT_BIEGE}
          fontFamilySrc="https://fonts.googleapis.com/css?family=Jost:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600"
        />
      }
    </My.Container>
  )

};

SliceStrategicIntelligence.defaultProps = SliceStrategicIntelligenceDefaultProps;
SliceStrategicIntelligence.cls = cls;