import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Slice } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { 
  useEntity, 
  SliceSectionColorBlock as BaseComponent, 
  SliceSectionColorBlockProps as BaseComponentProps, 
  useEntityOptions 
} from '@shapeable/ui';


import { LogoMark } from '../elements/logo-mark';
import { classNames } from '@shapeable/utils';
const cls = classNames('slice-section-color-block');

// -------- Types -------->

export type SliceSectionColorBlockProps = Classable & HasChildren & BaseComponentProps & { 
};

export const SliceSectionColorBlockDefaultProps: Omit<SliceSectionColorBlockProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`

  `,
});


const LogoMarkStyles = breakpoints({
  base: css`
    flex-shrink: 0;
    width: 40px;
    margin-right: 20px;
    margin-top: -8px;
    align-self: flex-start;
  `,
  tablet: css`
    width: 80px;
    margin-top: -20px;
  `,
});


// -------- Components -------->

const My = {
  Container: styled(BaseComponent)<ContainerProps>`${ContainerStyles}`,
  LogoMark: styled(LogoMark)`${LogoMarkStyles}`,
};

export const SliceSectionColorBlock: Shapeable.FC<SliceSectionColorBlockProps> = (props) => {
  const { className, children, ...rest } = props;
  const entity = useEntity(props.entity);
  const { showLogoMark } = useEntityOptions(entity);
  
  return (
   <My.Container entity={entity} preBody={showLogoMark && <My.LogoMark />} {...rest} className={cls.name(className)}>
   {children}
   </My.Container>
  )
};

SliceSectionColorBlock.defaultProps = SliceSectionColorBlockDefaultProps;
SliceSectionColorBlock.cls = cls;