import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { createIntroSlice } from '../../data';
import { BANNER_EVENT, COLOR_LIGHT_BROWN } from '../../theme';
import { SliceLayoutColorStrip, SliceLayoutIntro } from '../slice-layouts';
import { DarkMode, EntityTitleHeader } from '@shapeable/ui';

// -------- Child Component Props -------->

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div`${ContainerStyles}`,
};

export const PageLayoutEvents: PageLayoutComponent = (props) => {
  const { className, entity, slices } = props;
  const introSlice = createIntroSlice(entity);
  introSlice.backgroundColor = COLOR_LIGHT_BROWN;

  return (
    <My.Container className={className}>
      <EntityTitleHeader backgroundImage={BANNER_EVENT} entity={entity} />
      <SliceLayoutColorStrip />
      <DarkMode>
      <SliceLayoutIntro entity={introSlice} />
      </DarkMode>
      {slices}
    </My.Container>
  )
};
