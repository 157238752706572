import React from 'react';
import { IconComponent } from '@shapeable/types'

export const LogoMark: IconComponent = ({ className }) => (
  <svg className={className} viewBox="0 0 114 115" fill="#FFF" xmlns="http://www.w3.org/2000/svg">
    <path d="M69.4999 82C67.0999 84.8 63.5999 84.8 61.1999 82.1L42.6999 61.7C40.0999 58.8 36.2999 59.1 33.8999 62.4L25.2999 72.3L19.8999 78.6C27.2999 91.2 40.9999 99.7 56.6999 99.7C67.7999 99.7 77.8999 95.4 85.4999 88.5L77.4999 81.5C74.9999 79.2 71.7999 79.4 69.4999 82Z" />
    <path d="M43.6999 40.6L59.8999 70.2C61.9999 74 65.9999 74.1 68.1999 70.3C70.1999 66.8 73.7999 66.5 75.9999 69.7L87.2999 86.7C94.6999 79 99.2999 68.5 99.2999 57C99.2999 33.4 80.1999 14.3 56.5999 14.3C32.9999 14.3 13.8999 33.4 13.8999 57C13.8999 63.6 15.3999 69.7 18.0999 75.3L35.3999 40.8C37.4999 36.7 41.5999 36.7 43.6999 40.6Z" />
    <path d="M56.7 1.00001C83.3 -0.199995 108.1 20.2 112.5 46.4C113.1 50.1 113.4 53.8 113.3 57.5H111.6C111.5 53.9 111.1 50.3 110.3 46.8C106 25.6 88.5 8.10001 67.4 3.70001C63.9 2.90001 60.3 2.50001 56.7 2.30001V1.00001Z" strokeWidth="0.6938" strokeMiterlimit="10"/>
    <path d="M56.7 114C30.1 115.2 5.29995 94.8 0.899951 68.6C0.199951 64.9 -0.100049 61.2 -4.88274e-05 57.5H1.69995C1.79995 61.1 2.19995 64.7 2.99995 68.2C7.29995 89.4 24.7999 106.9 46 111.3C49.5 112.1 53.1 112.5 56.7 112.7V114Z" stroke="white" strokeWidth="0.6938" strokeMiterlimit="10"/>
  </svg>
);