import React from 'react';
import { Classable, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, ColorTokens, theme } from '@shapeable/theme';
import { 
  PageContextDefinition,  
  FocusElementProvider, useEmbedComponents, 
  EmbedComponentsProvider, AppStateProvider,
  UiConfig,
  UiProvider,
  LayoutShell,
  SiteFooterLayout,
  DataHook,
  usePages,
  DarkMode,
  useActiveThemeMode,
} from '@shapeable/ui';

import { VERY_LIGHT_BROWN, DARK_GREEN, GREEN, LIGHT_BROWN, GOLD, BLUE, DARK_RED, MEDIUM_BROWN } from '../theme';
import * as pageLayouts from './page-layouts';
import * as sliceLayouts from './slice-layouts';
import { LANG_STRINGS } from '../lang';
import { PostsProvider } from '../gatsby/providers/posts-provider';
import { MenusProvider } from '../gatsby/providers/menus-provider';
import { OrganisationPartnersProvider } from "../gatsby/providers/organisation-partners-provider";
import { StrategicIntelligenceWidget } from "./elements/strategic-intelligence-widget";
import { LayoutStyles, themeOverrides } from '../theme';
import { ReflectionsProvider } from '../gatsby/providers/reflections-provider';

export type LayoutPropTypes = Classable & HasChildren & {
  path?: string;
  data?: any;
  pageContext?: PageContextDefinition;
};

const LayoutDefaultProps: LayoutPropTypes = {
};

// -------- Styles --------> 

const ContainerStyles = breakpoints({
  base: css`
    ${LayoutStyles}
  `,
});

const FooterStyles = breakpoints({
  base: css`
    flex-grow: 0;
    flex-shrink: 0;
  `,
});

const BodyStyles = breakpoints({
  base: css`
    flex-grow: 1;
  `,
});


// -------- Components -------->

const My = {
  Container: styled(LayoutShell)`${ContainerStyles}`,
  Body: styled.div`${BodyStyles}`,
  Footer: styled(SiteFooterLayout)`${FooterStyles}`,

};

export const Layout: React.FC<LayoutPropTypes> = (props) => {
  const { className, children } = props;

  const embedComponents = useEmbedComponents();

  const ref = React.useRef();
  const focusRef = React.useRef();

  const menuColorTokens: ColorTokens.Any = {
    bold: GOLD,
    hover: {
      text: 'strong',
      bold: 'strong',
      background: BLUE,
    },
    active: {
      text: GOLD,
    },
  };

  const config: UiConfig = {
    layouts: { pageLayouts, sliceLayouts, defaultPageLayout: pageLayouts.PageLayoutDefault },
    langStrings: LANG_STRINGS,
    theme: themeOverrides,
    header: {
      barHeight: 3,
      showThemeMode: true,
    },
    search: {
      isAvailable: true,
      extraDataHooks: {
        Page: usePages as DataHook,
      },
    },
    footer: {
      buttonHoverColor: LIGHT_BROWN,
    },
    entity: {
      dataProviders: [
        MenusProvider, OrganisationPartnersProvider,
        PostsProvider, ReflectionsProvider,
      ]
    },
    componentProps: {
      MenuItem: {
        colors: {
          bold: GOLD,
          hover: {
            text: 'strong',
            bold: 'strong',
            background: BLUE,
          },
          active: {
            text: GOLD,
          },
        }
      },
      PageHeader: {
        themeMode: 'dark',
      },
      SiteMenuBar: {
        colors: {
          background: GREEN,
        }
      },
      HeaderDivider: {
        colors: {
          border: LIGHT_BROWN,
          text: LIGHT_BROWN,
          dark: {
            border: 'light',
            text: 'light',
          }
        }
      },
      SiteFooterLayout: {
        colors: {
          background: VERY_LIGHT_BROWN,
          dark: {
            background: BLUE,
          }
        }
      },
      Button: {
        colors: {
          hover: {
            border: 'primary',
            fill: 'primary',
            text: 'primary'
          },
        }
      }
    }
  };

  return (
    <UiProvider value={config}>
    <FocusElementProvider value={focusRef}>
    <EmbedComponentsProvider value={{
      ...embedComponents,
      StrategicIntelligence: StrategicIntelligenceWidget
    }}>
      <My.Container
        id="#container"
        ref={ref}
        hideOverflow={false}
        className={className}
        includeTooltip
        modalColor={DARK_GREEN}
        tooltipProps={{
          backgroundColor: VERY_LIGHT_BROWN,
          globalEventOff: 'click',
        }}
      >
        <My.Body>
          {children}
        </My.Body>
        <My.Footer backgroundColor={VERY_LIGHT_BROWN} />
      </My.Container>

    </EmbedComponentsProvider>
    </FocusElementProvider>
    </UiProvider>
  );
  
  
};

Layout.defaultProps = LayoutDefaultProps;

