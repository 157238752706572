import React, { useState } from 'react';
import { Classable, Dictionary, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';

import { ErrorList, Field, useForm, FormButton, FormLoader, Input, SelectOption, TextArea, usePage, useReady, useLink, useLang, InputCheckbox, Button, useLangStrings, useActiveLang, MarkdownContent, MarkdownContentLink, MarkdownContentLinkTargetBlank } from '@shapeable/ui';
import { DottedChevronDownIconGlyph } from '@shapeable/icons'
import EmailValidator from 'email-validator';
import { includes, trim, without } from 'lodash';
import ReCAPTCHA from 'react-google-recaptcha';
import validUrl from 'valid-url';
import { sprintf } from 'sprintf-js';


// -------- Types -------->

export type ContactFormProps = Classable & HasChildren & {
  beforeSubmit?: () => void;
  afterSubmit?: () => void;
}

export const ContactFormDefaultProps: ContactFormProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const FieldStyles = breakpoints({
  base: css`
    align-self: stretch;
    label {
      color: ${theme.COLOR('text')};
    }
  `,
});

const FormStyles = breakpoints({
  base: css`
    padding-bottom: ${theme.UNIT(3)};
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
});

const BaseInputStyles = breakpoints({
  base: css`
    border: 2px dotted ${theme.COLOR('secondary')};
    color: ${theme.COLOR('primary')};

    &:focus {
      outline: none;
      border: 2px dotted ${theme.COLOR('link-hover')};
    }

    ${({ hasError }: any ) => hasError && css`
      outline: none;
      border-color: red;
    `}
  `,
});



const TextAreaStyles = breakpoints({
  base: css`
    width: 100%;
    height: 150px;
    padding: ${theme.UNIT(4)};
    ${BaseInputStyles};
  `,
});


const InputStyles = breakpoints({
  base: css`
    width: 100%;
    padding: ${theme.UNIT(4)};
    color: ${theme.COLOR('primary')};
    ${BaseInputStyles};
  `,
});

const SubmitButtonStyles = breakpoints({
  base: css`  
    width: 200px;
    margin-top: ${theme.UNIT(8)};
  `,
});


const LoaderStyles = breakpoints({
  base: css`
    background: none;
    padding-bottom: ${theme.UNIT(100)};
  `,
});

const ThankyouStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(2)};
    width: 100%;
    box-sizing: border-box;
    flex-grow: 1;
    color: ${theme.COLOR('text')};
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    text-align: center;
    p {
      margin: ${theme.UNIT(2)};
    }
  `,
});

const ThankyouMessageStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    font-size: 1.25em;
  `,
});


const IndicatorStyles = breakpoints({
  base: css`
    width: 20px;
    margin: 0 16px;
  `,
});

const InputCheckboxStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('text')};
    margin: ${theme.UNIT(1)} 0;
    align-self: stretch;
  `,
});

const UpdatesStyles = breakpoints({
  base: css`
    ${InputCheckboxStyles};
    margin-bottom: ${theme.UNIT(8)};
  `,
});



const ErrorsStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('red')};
    padding: ${theme.UNIT(6)} ${theme.UNIT(6)} ${theme.UNIT(7)};
    margin-top: ${theme.UNIT(4)};
    margin-bottom: ${theme.UNIT(4)};
    font-weight: 500;
    border: 2px dotted ${theme.COLOR('red')};
    font-size: ${theme.FONT_SIZE(14)};

    ul {
      padding-left: ${theme.UNIT(4)};
    }

    li {
      margin: ${theme.UNIT(2)} 0; 
      padding-left: 0;
    }
  `,
});

// -------- Components -------->

const MESSAGE = 'details';
const LINK = 'link';
const GIVEN_NAME = 'givenName';
const FAMILY_NAME = 'familyName';
const EMAIL = 'email';
const JOB_TITLE = 'jobTitle';
const COMPANY = 'company';
const URL = 'url';
const FORM_NAME = 'contact';
const AGREE = 'agree';
const BECOME_MEMBER = 'becomeAMember';
const RECEIVE_UPDATES = 'receiveUpdates';


const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Loader: styled(FormLoader)`${LoaderStyles}`,
    Thankyou: styled.div`${ThankyouStyles}`,
      ThankyouMessage: styled.div`${ThankyouMessageStyles}`,

    Agree: styled(InputCheckbox).attrs({ id: AGREE, name: AGREE })`${InputCheckboxStyles}`,
    BecomeAMember: styled(InputCheckbox).attrs({ id: BECOME_MEMBER })`${InputCheckboxStyles}`,
    Updates: styled(InputCheckbox).attrs({ id: RECEIVE_UPDATES })`${UpdatesStyles}`,
    
    Indicator: styled(DottedChevronDownIconGlyph)`${IndicatorStyles}`,

    Form: styled.form`${FormStyles}`,

      GivenNameField: styled(Field).attrs({ id: GIVEN_NAME })`${FieldStyles}`,
        GivenName: styled(Input).attrs({ id: GIVEN_NAME, placeholder: '' })`${InputStyles}`,
      FamilyNameField: styled(Field).attrs({ id: FAMILY_NAME })`${FieldStyles}`,
        FamilyName: styled(Input).attrs({ id: FAMILY_NAME, placeholder: '' })`${InputStyles}`,
      MessageField: styled(Field).attrs({ id: MESSAGE })`${FieldStyles}`,
        Message: styled(TextArea).attrs({ id: MESSAGE })`${TextAreaStyles}`,
      EmailField: styled(Field).attrs({ id: EMAIL })`${FieldStyles}`,
        Email: styled(Input).attrs({ id: EMAIL, placeholder: '' })`${InputStyles}`,
      CompanyField: styled(Field).attrs({ id: COMPANY })`${FieldStyles}`,
        Company: styled(Input).attrs({ id: COMPANY, placeholder: '' })`${InputStyles}`,
      JobTitleField: styled(Field).attrs({ id: JOB_TITLE })`${FieldStyles}`,
        JobTitle: styled(Input).attrs({ id: JOB_TITLE, placeholder: '' })`${InputStyles}`,
      UrlField: styled(Field).attrs({ id: URL })`${FieldStyles}`,
        Url: styled(Input).attrs({ id: URL, placeholder: 'Company Website URL' })`${InputStyles}`,

      Errors: styled(ErrorList)`${ErrorsStyles}`,

    SubmitButton: styled(Button)`${SubmitButtonStyles}`,
};

const onSubmitForm: (url: string, data: Dictionary<any>) => Promise<any> =
  async (url, data) => {

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    return fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify({
          data: data,
          external_domain_token: process.env.FULL_FABRIC_FORM_TOKEN,
        }),
      }).then(response => response.text())
        .then(result => { })
        .catch(error => console.log('error', error));
  };
  
export const ContactForm: React.FC<ContactFormProps> = (props) => {
  const { className } = props;
  const form = useForm(FORM_NAME);

  const { values, patchValues } = form;
  const [ token, setToken ] = useState('');
  const [ errors, setErrors ] = useState<string[]>([]);
  const [ errorFields, setErrorFields ] = useState<string[]>([]);

  const { Link } = useLink();

  const t = useLang();
  const lang = useActiveLang();

  const page = usePage();
  
  const formState: Dictionary<any> = { 
    ...values, 
  };

  const onChange = (name: string) => (value: string | SelectOption) => { patchValues({ [name]: value }); setErrors([]); setErrorFields(without(errorFields, name)); };

  const onSubmit = async(event: React.FormEvent) => {
    event.preventDefault();

    const e = [];
    const ef = [];
    
    if (!token) {
      errors.push(t("Please check the I'm not a robot checkbox to validate you are human"));
    }

    if (trim(formState[GIVEN_NAME]) === '') {
      e.push(t("Please enter your given name."));
      ef.push(GIVEN_NAME);
    }

    if (trim(formState[FAMILY_NAME]) === '') {
      e.push(t("Please enter your family name."));
      ef.push(FAMILY_NAME);
    }

    if (trim(formState[MESSAGE]) === '') {
      e.push(t("Please provide the details of your request in the Message field."));
      ef.push(MESSAGE);
    }

    if (trim(formState[EMAIL]) !== '' && !EmailValidator.validate(formState[EMAIL])) {
      e.push(t("Please check that your email address is VALID"));
      ef.push(EMAIL);
    }

    if (trim(formState[URL]) !== '' && !validUrl.isWebUri(formState[URL])) {
      e.push(t("Please provide a VALID URL in the Company Website URL field"));
      ef.push(URL);
    }

    if (trim(formState[AGREE]) === '') {
      e.push(t("Please confirm you agree with our terms and privacy policy"));
      ef.push(AGREE);
    }

    setErrorFields(ef);
    setErrors(e);

    if (e.length) {
      return false;
    }

    // convert form state to format accepted by Full Fabric'
    
    const data = {
      first_name: formState[GIVEN_NAME],
      last_name: formState[FAMILY_NAME],
      email: formState[EMAIL],
      __company: formState[COMPANY],
      __job_title: formState[JOB_TITLE],
      __message: formState[MESSAGE],
      __i_would_like_to_become_a_villars_institute_member: (!!formState[BECOME_MEMBER] ? 'yes' : null),
      policies: {
        '6233e296976a7753a3000258': {
          'accepted': true,
        },
        '6233e296976a7753a300025a': {
          'accepted': !!formState[RECEIVE_UPDATES],
          "options": {
            "email": "true",
            "phone": "true",
            "sms": "false",
            "mail": "false"
          },
        }
      }
    };

    // const data = { ...formState, 'g-recaptcha-response': token };

    if (props.beforeSubmit) {
      props.beforeSubmit();
    };

    form.startSubmit();
    const response = await onSubmitForm(process.env.FULL_FABRIC_FORM_ACTION, data);
    // const response = await onSubmitNetlifyForm(FORM_NAME, page.path, data);
    
    setToken('');
    form.endSubmit();

    if (props.afterSubmit) {
      props.afterSubmit();
    };

    return false;

  };

  const onCaptchaChange = (token: any) => {
    setToken(token);
  };

  const isSubmitDisabled = !!((!token && process.env.SITE_RECAPTCHA_KEY) || form.isSubmitting);
  const hasErrors = !!errors.length;
  const { isReady } = useReady();

  return (
    <My.Container className={className}>
      {
        (form.isSubmitting) && 
        <My.Loader color="primary">{t("Please wait...")}</My.Loader>
      }
      {
        !form.isSubmitting && (
        (form.isSubmitted) ?
        <My.Thankyou>
          <My.ThankyouMessage>
          <p>
          {t("Thankyou, your submission has been received.")}
          </p>
          <p>
          {t('We will be in touch soon.')}
          </p>
          </My.ThankyouMessage>
        </My.Thankyou> :
        <My.Form name={FORM_NAME} onSubmit={onSubmit} method="post">
          <input type="hidden" name="form-name" value={FORM_NAME} />
          <My.GivenNameField label={t('Given Name (Required):')}>
            <My.GivenName hasError={includes(errorFields, GIVEN_NAME)} onChange={onChange(GIVEN_NAME)} value={formState[GIVEN_NAME] || ''} />
          </My.GivenNameField>

          <My.FamilyNameField label={t('Family Name (Required):')}>
            <My.FamilyName hasError={includes(errorFields, FAMILY_NAME)} onChange={onChange(FAMILY_NAME)} value={formState[FAMILY_NAME] || ''} />
          </My.FamilyNameField>

          <My.EmailField label={t('Email:')}>
            <My.Email hasError={includes(errorFields, EMAIL)} onChange={onChange(EMAIL)} value={formState[EMAIL] || ''} />
          </My.EmailField>

          <My.CompanyField label={t('Company:')}>
            <My.Company hasError={includes(errorFields, COMPANY)} onChange={onChange(COMPANY)} value={formState[COMPANY] || ''} />
          </My.CompanyField>

          <My.JobTitleField label={t('Job Title:')}>
            <My.JobTitle hasError={includes(errorFields, JOB_TITLE)} onChange={onChange(JOB_TITLE)} value={formState[JOB_TITLE] || ''} />
          </My.JobTitleField>

          <My.MessageField label={t('Message:')}>
            <My.Message hasError={includes(errorFields, MESSAGE)} onChange={onChange(MESSAGE)}>{formState[MESSAGE] || ''}</My.Message>
          </My.MessageField>

          <My.Agree onChange={onChange(AGREE)} valid={!includes(errorFields, AGREE)} checked={formState[AGREE] === 'yes'}>
          <MarkdownContent 
            text={sprintf(t("I confirm that I have read and agree to the [terms](%s) and [privacy policy](%s) of %s."), process.env.TERMS_PATH, process.env.PRIVACY_PATH, process.env.ORG_NAME)} 
            embedComponents={{
              a: {
                component: MarkdownContentLinkTargetBlank
              }
            }}
          />
          </My.Agree>
          
          <My.BecomeAMember label={sprintf(t('I would like to become a %s member'), process.env.ORG_NAME)} onChange={onChange(BECOME_MEMBER)} checked={formState[BECOME_MEMBER] === 'yes'} />
          <My.Updates label={sprintf(t(`I\'d like to receive updates from %s`), process.env.ORG_NAME)} name="agree" onChange={onChange(RECEIVE_UPDATES)} checked={formState[RECEIVE_UPDATES] === 'yes'} />
          
          {
            isReady && process.env.SITE_RECAPTCHA_KEY && 
            <ReCAPTCHA
              hl={lang.iso}
              sitekey={process.env.SITE_RECAPTCHA_KEY}
              onChange={onCaptchaChange}
            />
          }
          {
            hasErrors && 
            <My.Errors items={errors}>
            {t('Sorry, we need a bit more information:')}
            </My.Errors>
          }
          <My.SubmitButton type="submit" disabled={isSubmitDisabled}>{t('Submit')}</My.SubmitButton>
        </My.Form>
        )
      } 
      
    </My.Container>
  )
};

ContactForm.defaultProps = ContactFormDefaultProps;