import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { SliceLayoutBoundary, useEntity, PageKnowledgeHubLayout, EntityTitleHeader } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
import { BANNER_KNOWLEDGE_HUB, BLUE_OVERLAY } from '../../theme';
import { useReflections } from '../../hooks/use-reflections';
import { SliceLayoutColorStrip, SliceLayoutContentImage } from '../slice-layouts';
import { createContentSlice } from '../../data';


const cls = classNames('page-layout-reflections');

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
   .shp--card__content .shp--cell-header {
     display: none;
   }

   .shp--slice-block-image__image {
    width: 90%;
  }
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
};

export const PageLayoutReflections: PageLayoutComponent = 
(props) => {
  const { className, children, slices } = props;
  const entity = useEntity(props.entity);

  const { outro } = entity;

  const outroSlice = createContentSlice(entity);
  const hasOutro = outro && outro.text;

  const reflectionProps = { 
    Post: { 
      label: 'Reflections', 
      items: useReflections(), 
      sortBy: 'published', 
      reverse: true,
      filterFieldNames: ['challenges', 'topics', 'authors'],
     }
  }


  return (
    <My.Container className={cls.name(className)}>
      <EntityTitleHeader overlayColor={BLUE_OVERLAY} backgroundImage={BANNER_KNOWLEDGE_HUB} entity={entity} />
      <SliceLayoutColorStrip />
      {slices}
      <SliceLayoutBoundary boundary='content-bleed' >
        <PageKnowledgeHubLayout 
          entity={entity} 
          entityTypes={reflectionProps}
          filterSize='slim'
          filterSpacing={3}
          />
      </SliceLayoutBoundary>
      {
        hasOutro && <SliceLayoutContentImage entity={outroSlice} />
      }
     
    </My.Container>
  )
};