import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import Helmet from 'react-helmet';
import { toString, mapKeys } from 'lodash';
import { useReady } from '@shapeable/ui';

// -------- Types -------->

export type StrategicIntelligenceWidgetProps = Classable & HasChildren & {
  apiKey: string;
  topicId: string;
  labelText?: string;
  headerText?: string;
  buttonText?: string;
  isCollapsible?: boolean;
  isInitialisedCollapsed?: boolean;
  positionOfWordsToHighlight?: number[];
  initialIndex?: number;
  showExploreNavigationButtons?: boolean;
  language?: "en" | "pt" | "zh" | "es" | "ja" | "ar" | "fr" | "de" | "hi"  | "ko";
  mode?: "auto" | "narrow"| "normal" | "wide";
  wrapperColor?: string;
  primaryColor?: string;
  secondaryColor?: string;
  tertiaryColor?: string;
  headerColor?: string;
  subtitleColor?: string;
  fontFamilySrc?: string;
}

export const StrategicIntelligenceWidgetDefaultProps: Omit<StrategicIntelligenceWidgetProps, "topicId" | "apiKey"> = {
  labelText: '',
  headerText: '',
  buttonText: '',
  initialIndex: 0,
  isCollapsible: false,
  isInitialisedCollapsed: false,
  positionOfWordsToHighlight: [],
  showExploreNavigationButtons: true,
  language: "en",
  mode: "auto",
};

// -------- Child Component Props -------->

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<any>`${ContainerStyles}`,
};

export const StrategicIntelligenceWidget: React.FC<StrategicIntelligenceWidgetProps> = (props) => {
  const { className, apiKey, topicId, positionOfWordsToHighlight, isCollapsible, isInitialisedCollapsed, showExploreNavigationButtons, ...rest } = props;

  const { isReady } = useReady();

  if (!apiKey) {
    console.warn("Strategic Intelligence Widget Cannot be used without apiKey!");
  }

  if (!topicId) {
    return null;

  }

  const widgetProps = mapKeys(rest, (value, key) => key.toLowerCase());

  return (
    <My.Container className={className}>
      <Helmet>
        <script type="text/javascript" src="https://cdn.intelligence.weforum.org/insiw/v1/index.js"></script>
      </Helmet>

      {
        isReady &&
        /* @ts-ignore */
        <insi-widget
          {...widgetProps}
          iscollapsable={isCollapsible ? "true" : "false"}
          isinitialisedcollapsed={isInitialisedCollapsed ? "true" : "false"}
          showexplorenavigationbuttons={showExploreNavigationButtons ? "true" : "false"}
          positionofwordstohighlight={positionOfWordsToHighlight ? `[${toString(positionOfWordsToHighlight)}]` : ''}
          topicid={topicId}
          apikey={process.env.WEF_STRATEGIC_INTELLIGENCE_KEY}
        />
      }

    </My.Container>
  )
};

StrategicIntelligenceWidget.defaultProps = StrategicIntelligenceWidgetDefaultProps;